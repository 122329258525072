<template>
  <section class="JCI container970">
    <div class="top-banner">
      <img src="../../assets/images/public/small_banner.png" alt="" class="small_banner">
      <h3>Other</h3>
      <el-button type="primary" size="large" @click="$router.push('/other-form')">Make a Donation</el-button>
    </div>
    <div style="
        background: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      ">
      <div class="p-2" v-for="v of otherList" :key="v.id" style="border-bottom:1px solid #eee">
        <a :href="v.website" target="_blank" style="color:#777d74">
          <div class="row p-0">
            <div class="col-2">
              <img class="avatar-120" :src="v.logo" alt="" />
            </div>
            <div class="col">
              <p>{{ v.name }}</p>
              <p>{{ v.introduction }}</p>
            </div>
          </div>
        </a>
      </div>

    </div>

    <el-card class="table-card mt-3">
      <div class="row mb-3 p-1" style="border-bottom: 1px solid #eee">
        <div class="col-5">
          <h3>Donors</h3>
        </div>
        <div class="col-3">
          <el-input v-model="name" @change="page=1,getDonateRecord()" placeholder="Filter by name"
            suffix-icon="el-icon-search" clearable>
          </el-input>
        </div>
        <div class="col-4">
          <el-select class="w-100" v-model="organization_id" filterable remote reserve-keyword
            :remote-method="organizationSearch" @change="page=1,getDonateRecord()"
            placeholder="Enter keyword search organization" clearable>
            <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table :header-row-style="{ background: '#f00 !important' }" :data="tableData" style="width: 100%">
        <!-- <el-table-column prop="avatar" label="Avatar" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <div class="user-img img-fluid mr-2">
                <img :src="scope.row.avatar" class="rounded-circle avatar-40" />
              </div>
              <p class="m-0">
                {{ scope.row.donor_first_name + scope.row.donor_last_name }}
              </p>
              {{ scope.row }}
            </div>
          </template>
        </el-table-column> -->

        <!-- <el-table-column prop="avatar" label="Avatar" width="70">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <div class="user-img img-fluid">
                <el-avatar :size="40" :src="scope.row.avatar" @error="true">
                  <img src="@/assets/images/public/user.png" />
                </el-avatar>
              </div>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column show-overflow-tooltip label="Donor">
          <template slot-scope="scope">
            <span class="m-0">
              {{ scope.row.donor_first_name+' '+scope.row.donor_last_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="donor_country" label="Country" show-overflow-tooltip></el-table-column>
        <el-table-column prop="organization" label="Organization" show-overflow-tooltip></el-table-column>
        <el-table-column prop="donate_to" label="Donate to" show-overflow-tooltip></el-table-column>
        <el-table-column prop="donation_amount" label="Amount(USD)"></el-table-column>
        <el-table-column prop="donation_at" label="Datetime" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="py-3" style="text-align: right;">
        <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" :page-size="size"
          @current-change="changePage" hide-on-single-page>
        </el-pagination>
      </div>
    </el-card>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        tableData: [],
        name: "",
        organization_id: "",
        orgList: [],
        otherList: [],
        page: 1,
        size: 12,
        total: 0
      };
    },

    components: {},

    created() {
      this.getDonateRecord();
      this.getOtherOrganizationList();
    },

    methods: {
      getOtherOrganizationList() {
        this.$http.getOtherOrganizationList().then((res) => {
          if (res.status == 200) {
            this.otherList = res.data;
          }
        });
      },

      organizationSearch(query) {
        this.$http
          .getOtherOrganizationList({ name: query })
          .then((res) => {
            if (res.status == 200) {
              this.orgList = res.data;
            }
          });
      },

      getDonateRecord() {
        this.$http
          .getDonateRecord({
            donated_type: 3,
            page: this.page,
            per_page: 12,
            name: this.name,
            donor_organization_id: this.organization_id
          })
          .then((res) => {
            this.tableData = res.data.data;
            this.total = res.data.total
          });
      },
      changePage(value) {
        this.page = value
        this.getDonateRecord()
      }
    },
  };
</script>
<style lang="scss" scoped>
  .JCI {
    .top-banner {
      // height: 230px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      /* background: url("../../assets/images/page-img/profile-bg1.jpg") no-repeat 100% 100%; */
      background-color: rgb(66, 102, 176);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .small_banner {
        height: 100%;
        position: absolute;
        // left: 0;
      }

      >h3 {
        color: #fff;
        font-size: 30px;
        margin-bottom: 37px;
      }

      >.el-button {
        /* width: 100px; */
      }
    }
  }
  @media (min-width: 600px) {
    .top-banner {
      height: 230px;
      .small_banner {
        left: 70px;
      }
    }
  }
  @media (max-width: 600px) {
    .top-banner{
      height: 180px;
      h3{
        font-size: 16px !important;
      }
      .small_banner {
        width: 70px;
        left: 25px;
      }
    }
  }
</style>